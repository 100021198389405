import {
  ArrowBack,
  Close,
  DashboardCustomize,
  ExitToAppOutlined,
  SettingsOutlined,
} from "@mui/icons-material";
import { AppBar, IconButton, Toolbar, Typography } from "@mui/material";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import LogoutDialog from "./LogoutDialog";

//Header bar for all pages
export default function HeaderAppBar({ closeAction, settingAction, logoutAction }) {

  const navigate = useNavigate();
  //show 
  const [showLogout, setShowLogout] = React.useState(false);

  //Show the logout dialog
  const handleLogout = () => {
    setShowLogout(true);
  };
  //Navigate to the settings page
  const handleSettings = () => {
    navigate("/settings");
  };


  //We are passing in some options from the 'Page' to give us context
  return (
    <>
      <AppBar
        position="absolute"
        color="default"
        elevation={2}
        sx={{
          position: "relative",
          borderBottom: (t) => `1px solid ${t.palette.divider}`,
        }}
      >
        <Toolbar>
          {closeAction ? (
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={closeAction}
            >
              <ArrowBack />
            </IconButton>
          ) : (
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={()=>{
                navigate("/")
              }}
            >
              <img
                src="forghetti-app-icon-192px.png"
                alt="logo"
                width={40}
                height={40}
              />

            </IconButton>
          )}
          <Typography
            variant="h6"
            color="inherit"
            noWrap
            component="div"
            sx={{ flexGrow: 1 }}
          >
            Forghetti Partner Portal
          </Typography>
          {closeAction && (
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ ml: 2 }}
              onClick={closeAction}
            >
              <Close></Close>
            </IconButton>
          )}
          {settingAction && (
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ ml: 2 }}
              onClick={handleSettings}
            >
              <SettingsOutlined />

              <Typography noWrap variant="subtitle1" sx={{ pl: 1 }}>
                Settings
              </Typography>
            </IconButton>
          )}
          {logoutAction && (
            <IconButton
              size="large"
              edge="start"
              color="red"
              aria-label="menu"
              sx={{ mx: 2 }}
              onClick={handleLogout}
            >
              <ExitToAppOutlined />
              <Typography noWrap variant="subtitle1" sx={{ pl: 1 }}>
                Logout
              </Typography>
            </IconButton>
          )}
        </Toolbar>
      </AppBar>
      {logoutAction && (
        <LogoutDialog
          open={showLogout}
          setOpen={setShowLogout}
          logoutAction={logoutAction}
        ></LogoutDialog>
      )}
    </>
  );
}
