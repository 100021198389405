import {
  Backdrop,
  CircularProgress,

} from "@mui/material";
import * as React from "react";

//Logout dialog
export default function LoadingScreenBackdrop({ open, setOpen, closeAction }) {
  const handleClose = () => {
    setOpen(false);
    if (closeAction) {
    closeAction();
    }
  };

  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
      onClick={() => {
        if (closeAction) {
          handleClose();
        }
      }}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}
