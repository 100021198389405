import { Alert, Box, Grid, Paper, Typography } from "@mui/material";
import * as React from "react";
import { CopyBlock, dracula } from "react-code-blocks";

export default function WebHookInfo({ show, setShow }) {
  const [showHook, setShowHook] = React.useState(false);
  return (
    <Box mt={2} sx={{}}>
      <Grid
        item
        xs={12}
        justifyContent={"flex-end"}
        mt={2}
        sx={{ width: "100%" }}
        onClick={() => {
          setShowHook(!showHook);
        }}
      >
        <Alert
          fullWidth
          severity="info"
          color="secondary"
          sx={{
            ml: 0,
            pl: 1,
            borderBottomRightRadius: showHook?  0 : 2, borderBottomLeftRadius: showHook?  0 : 2
          }}
        >
           Click here to learn how to use webhook
        </Alert>
      </Grid>

      {showHook && <Paper elevation={2} sx={{ padding: 2 }}>
        

        <Typography variant="subtitle1">
          The Webhook hook allows you to receive the username, email and
          password from a HTTP call. Each request has a unique ID. And is signed
          with a MAC so you can verify your request
        </Typography>

        <Typography marginTop={2} variant="h6" sx={{ color: "secondary.main" }}>
          Connection established
        </Typography>

        <Typography variant="subtitle1">
          Called when the socket is opened. This is the first hook that will be
          called. You can use this to check communication with the forghetti
          server has been established.
        </Typography>
        <CopyBlock
          text={
            'server.post("/forghettiWebhook", (req, res) => {\n console.log("Socket initialised", req.body.id, req.body.action, req.body.message)`\n})'
          }
          language="javascript"
          showLineNumbers={false}
          theme={dracula}
        ></CopyBlock>

        <Typography marginTop={2} variant="h6" sx={{ color: "secondary.main" }}>
          Password received
        </Typography>

        <Typography variant="subtitle1">
          Called when the user has sent their password. You can then use this to
          inject the username, email and password into your site.
        </Typography>
        <CopyBlock
          text={
            'server.post("/forghettiWebhook", (req, res) => {\n console.log("Password received", req.body.id, req.body.username, req.body.email, req.body.password)`\n})'
          }
          language="javascript"
          showLineNumbers={false}
          theme={dracula}
        ></CopyBlock>
      </Paper> }
    </Box>
  );
}
