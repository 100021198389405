import {
  Alert,
  Box,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import * as React from "react";
import { CopyBlock, dracula } from "react-code-blocks";

export default function JavascriptHookInfo() {
  return (
    <Box mt={2}>
           <Grid
        item
        xs={12}
        justifyContent={"flex-end"}
        mt={2}
        sx={{ width: "100%" }}
      
      >
        <Alert
          severity="info"
          color="secondary"
          sx={{ ml: 0, pl: 1, borderBottomRightRadius: 0, borderBottomLeftRadius: 0}}

        >
          How to use the Javascript callback
        </Alert>
      </Grid>

      <Paper elevation={2} sx={{ padding: 2 }}>
    

      <Typography variant="subtitle2">
        The Javascript Hook is a few easy to use callbacks that allow you to
        create your own custom implementations of the login process.
      </Typography>

      <Typography marginTop={2} variant="h6" sx={{ color: "secondary.main" }}>
        Socket initialised
      </Typography>

      <Typography variant="subtitle2">
        Called when the socket is opened. This is the first callback that will
        be called. You can use this to check communication with the forghetti
        server has been established.
      </Typography>
      <CopyBlock
        text={
          'window.forghetti.callbacks.initialised(success) {\n console.log("Socket initialised")`\n}'
        }
        language="javascript"
        showLineNumbers={false}
        theme={dracula}
      ></CopyBlock>

      <Typography marginTop={2} variant="h6" sx={{ color: "secondary.main" }}>
        Password received
      </Typography>

      <Typography variant="subtitle2">
        Called when the user has sent their password. You can then
        use this to inject the username, email and password into your site.
        Perhaps you could even log the user in straight away?
      </Typography>
      <CopyBlock
        text={
          'window.forghetti.callbacks.passwordReceived({email, username, password}) {\n console.log("Password received", email, username, password)`\n}'
        }
        language="javascript"
        showLineNumbers={false}
        theme={dracula}
      ></CopyBlock>
      </Paper>
    </Box>
  );
}
