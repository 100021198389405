import { initializeApp } from "firebase/app";
//import { getAnalytics, isSupported } from "firebase/analytics";
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  isSignInWithEmailLink,
  signInWithEmailLink,
  updatePassword,
} from "firebase/auth";
import {
  getFirestore,
  setDoc,
  doc,
  deleteDoc,
  getDoc,
} from "firebase/firestore";

//This is ok to be exposed
const firebaseConfig = {
  apiKey: "AIzaSyC4ZTW76MDfUr5JC25Zm94SsX-jJ50tMpc",
  authDomain: "forghettiapp.firebaseapp.com",
  databaseURL: "https://forghettiapp.firebaseio.com",
  projectId: "forghettiapp",
  storageBucket: "forghettiapp.appspot.com",
  messagingSenderId: "196970253358",
  appId: "1:196970253358:web:4bfe0ac14e3f10edd923bf",
  measurementId: "G-JFH8ZR3GDT"
};

//Initialse the app
const app = initializeApp(firebaseConfig);
// if (isSupported()) {
// getAnalytics(app);
// }
const auth = getAuth(app);
const db = getFirestore(app);

//Just left in here for reference
// const googleProvider = new GoogleAuthProvider();
// const signInWithGoogle = async () => {
//   try {
//     const res = await signInWithPopup(auth, googleProvider);
//     const user = res.user;
//     const q = query(
//       collection(db, "socket_partner"),
//       where("uid", "==", user.uid)
//     );
//     const docs = await getDocs(q);
//     if (docs.docs.length === 0) {
//       await setDoc(collection(db, "socket_partner", user.uid), {
//         uid: user.uid,
//         name: user.displayName,
//         authProvider: "google",
//         email: user.email,
//       });
//     }
//   } catch (err) {
//     console.error(err);
//     alert(err.message);
//   }
// };

const logInWithEmailAndPassword = async (email, password) => {
  return await signInWithEmailAndPassword(auth, email, password);
};

//We create the firebase login and then we create the socket partner record in firestore
//Be aware we have listeners on the back that react to this data
const registerWithEmailAndPassword = async (name, email, password) => {
  const res = await createUserWithEmailAndPassword(auth, email, password);
  const user = res.user;
  try {
    await setDoc(doc(db, "socket_partner", user.uid), {
      id: user.uid,
      readers: [user.uid],
      writers: [user.uid],
      users: [
        {
          uid: user.uid,
          role: "owner",
          name: name,
          email: email,
          created: new Date(),
        },
      ],
      name,
      email,
      created: new Date(),
      partner: user.uid,
    });
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

//A trigger listens to reset_password collection and sends an email
const sendPasswordReset = async (email) => {
  //Delete any existing reset password requests
  //The trigger only listens on create
  const docRef = doc(db, "reset_password", email);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    await deleteDoc(docRef);
  }
  await setDoc(doc(db, "reset_password", email), {
    email,
    created: new Date(),
  });
  return true;
};

const logout = async () => {
  await signOut(auth);
};

//We use this to get the claims for the user. This tells us when they login what partner they belong to and what role they have

const getToken = async (force) => {
  const user = auth.currentUser;
  if (user) {
    return await user.getIdTokenResult(force);
  }
  return null;
};

//React to a passwordless login in attempt. We make them set a password for the future.
const passwordLessLogin = async (email, password, comppletePassword) => {
  if (comppletePassword !== password) {
    return { success: false, message: "Passwords do not match" };
  }
  if (isSignInWithEmailLink(auth, window.location.href)) {
    try {
      await signInWithEmailLink(auth, email, window.location.href);
      await updatePassword(auth.currentUser, password);
      return { success: true, message: "Login successful" };
    } catch (err) {
      return { success: false, message: err.message };
    }
  } else {
    return { success: false, message: "Invalid link" };
  }
};

//Current logged in user
const isLoggedIn = () => {
  return auth?.currentUser;
};

let roleProm;
const role = async () => {
  let token;
  if (roleProm) {
    token = await roleProm;
  } else {
    token = await getToken();
  }
  if (!token?.claims?.role) {
    if (token?.claims) {
      token.claims.role = "admin";
    }
    if (token) {
      token.claims = { role: "admin" };
    }
    if (!token) {
      token = { claims: { role: "admin" } };
    }
  }
  return token.claims.role;
};

const editPerms = {
  owner: ["UsersTable", "PartnerDetails", "Dashboard"],
  admin: ["UsersTable", "PartnerDetails", "Dashboard"],
  editor: ["PartnerDetails"],
  viewer: [],
  user: [],
};
const canEdit = async (name) => {
  const r = await role();

  if (editPerms[r].includes(name)) {
    console.log("can edit", name, r);
    return true;
  }
  return false;
};

export {
  app,
  auth,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
  getToken,
  isLoggedIn,
  passwordLessLogin,
  role,
  canEdit,
};
