
const scrape = async (site) => {
    try {
    fetch(site)
    .then(res => res.text())
    .then((responseText) => {
      const doc = new DOMParser().parseFromString(responseText, 'text/html');
      const h1 = doc.querySelector('h1');
      console.log(h1.textContent);
      return h1.textContent;
    });
} catch (error) {
    console.log(error);
    return { error: error }
}   
}

export { scrape };